<template>
  <b-modal id="add-style-modal" scrollable size="lg">
    <template v-slot:modal-header>
      <b-container>
        <b-row>
          <b-col>
            <h3>{{ trans('prospects.styles.add_new') }}</h3>
            <p class="description">
              {{ trans('prospects.styles.add_new_description') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:default>
      <b-container>
        <b-row>
          <b-col>
            <b-form class="margin-bottom-16" @submit.prevent>
              <b-form-group id="styletype_name_group" :label="trans('global.name')" label-for="styletype_name">
                <b-form-input
                  id="styletype_name"
                  v-model="$v.styleTypeForm.name.$model"
                  name="styletype_name"
                  class="form-control-lg"
                  :state="formErrors.name"
                  type="text"
                  :placeholder="trans('prospects.styles.form_add_new.name_placeholder')"
                  aria-describedby="styletype_name-msg"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="styletype_name-msg">
                  <span v-if="!$v.styleTypeForm.name.required">{{ trans('prospects.styles.form_add_new.error_name_length') }}</span>
                  <span v-if="!$v.styleTypeForm.name.minlength">{{ trans('prospects.styles.form_add_new.error_name_required') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="styleType_description_group" :label="trans('global.description')" label-for="styleType_description">
                <b-form-textarea
                  id="styleType_description"
                  v-model="$v.styleTypeForm.description.$model"
                  name="styleType_description"
                  class="form-control-lg"
                  :state="formErrors.description"
                  :placeholder="trans('prospects.styles.form_add_new.description_placeholder')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <b-form-invalid-feedback id="styletype_description-msg">
                  <span v-if="!$v.styleTypeForm.description.required">{{ trans('prospects.styles.form_add_new.error_description_required') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="styleType_image_group" :label="trans('prospects.styles.form_add_new.image')" label-for="styleType_image">
                <div :class="(!styleTypeImage) ? 'noimg': ''" class="vary-image-container" :style="styleTypeImage ? styleTypeImage : ''" style="width: 200px; height: 200px; padding-top: 16.67%; min-height: auto;">
                  <div v-if="!styleTypeImage" class="text">
                    <i class="fas fa-file-image" style="font-size: 40px;"></i>
                    <span>{{ trans('prospects.styles.form_add_new.image_description') }}</span>
                  </div>
                </div>
                <div v-if="uploadStatus === 1" class="vary-image-progress">
                  <b-progress :value="Math.round(progress)" variant="primary" striped :animated="true" show-progress></b-progress>
                </div>
                <b-form-file
                  id="styleType_image"
                  v-model="$v.styleTypeForm.image.$model"
                  :state="formErrors.image"
                  :placeholder="trans('prospects.styles.form_add_new.image_placeholder')"
                  class="margin-top-16"
                  accept="image/jpeg, image/png"
                  @change="upload"
                >
                </b-form-file>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
        {{ trans('global.cancel') }}
      </b-button>
      <b-button
        v-if="!imageFileStatus"
        ref="submitbtn"
        variant="primary"
        size="md"
        :style="{ width: submitbtnwidth ? submitbtnwidth : 'auto' }"
        class="padding-left-60 padding-right-60"
        disabled
        @click="createStyleType"
      >
        <span v-if="!loading">{{ trans('prospects.styles.save_style') }}</span>
        <b-spinner v-else variant="white"></b-spinner>
      </b-button>
      <b-button
        v-else
        ref="submitbtn"
        variant="primary"
        size="md"
        :style="{ width: submitbtnwidth ? submitbtnwidth : 'auto' }"
        class="padding-left-60 padding-right-60"
        @click="createStyleType"
      >
        <span v-if="!loading">{{ trans('prospects.styles.save_style') }}</span>
        <b-spinner v-else variant="white"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'

  const fb = require('../../../../firebaseConfig')

  export default {
    name: 'ModalAddUnit',
    mixins: [validationMixin],
    data () {
      return {
        uploadStatus: 0, // 0 = idle, 1 = progress, 2 = complete
        progress: 0,
        submitted: false,
        loading: false,
        imageFileStatus: false,
        submitbtnwidth: null,
        styleTypeForm: {
          name: '',
          description: '',
          image: {}
        }
      }
    },
    validations () {
      return {
        styleTypeForm: {
          name: {
            required,
            minlength: minLength(3)
          },
          description: {
            required
          },
          image: {
            /**
             * @return {boolean}
             */
            Uploaded () {
              return (
                this.imageFileStatus
              )
            }
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        prospects: state => state.prospects.prospects
      }),
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      formErrors () {
        return {
          name: (this.$v.styleTypeForm.name.$dirty && this.submitted) ? !this.$v.styleTypeForm.name.$error : null,
          description: (this.$v.styleTypeForm.description.$dirty && this.submitted) ? !this.$v.styleTypeForm.description.$error : null,
          image: (this.$v.styleTypeForm.image.$dirty && this.submitted) ? !this.$v.styleTypeForm.image.$error : null
        }
      },
      projectType () {
        return this.project !== undefined ? this.projectTypes[this.project.type] : 'loading'
      },
      prospect () {
        return this.prospects.find(prospect => prospect.slug === this.$route.params.prospectslug)
      },
      styleTypeImage () {
        return (Object.keys(this.styleTypeForm.image).length > 0) ? 'background-image: url("' + process.env.VUE_APP_IMAGE_CDN_URL + '/' + this.styleTypeForm.image.path + '/' + this.styleTypeForm.image.file + '")' : null
      }
    },
    methods: {
      createStyleType () {
        this.submitbtnwidth = this.$refs.submitbtn ? this.$refs.submitbtn.clientWidth : 'auto'
        this.loading = true
        this.submitted = true

        this.$v.styleTypeForm.$touch()

        if (this.$v.styleTypeForm.$anyError) {
          this.loading = false
          return
        }

        const id = this.prospect.styleTypes !== undefined ? parseInt(Object.keys(this.prospect.styleTypes).pop()) + 1 : 0

        if (this.imageFileStatus) {
          fb.prospectsCollection.doc(this.prospect._id).update({
            [`styleTypes.${id}`]: {
              createdOn: new Date(),
              id,
              name: this.styleTypeForm.name,
              description: this.styleTypeForm.description,
              image: this.styleTypeForm.image
            }
          }).then(() => {
            this.styleTypeForm.name = ''
            this.styleTypeForm.description = ''
            this.styleTypeForm.image = {}
            this.imageFileStatus = false
            this.submitted = false
            this.loading = false
            this.hideModal('add-style-modal')
            this.$store.commit('configs/setProjectTopbarMenu', 'styletypes')
          }).catch((err) => {
            console.log('Could not save styletype:')
            console.log(err)
          })
        } else {
          // Image is missing, do some error handling here
          console.log('Image is missing, fix it')
        }
      },
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      },
      upload (e) {
        const self = this
        this.uploadStatus = 1
        this.styleTypeForm.image = e.target.files[0]

        let randomTxt = ''
        const length = 16
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        for (let i = 0; i < length; i++) {
          randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
        }

        if (this.styleTypeForm.image !== undefined) {
          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: this.styleTypeForm.image.type
          }

          const numberOfStyles = this.prospect.styleTypes !== undefined ? Object.keys(this.prospect.styleTypes).length : 0

          const newFilename = this.project.slug + '_styletype_' + numberOfStyles + '_' + randomTxt + '.' + this.styleTypeForm.image.name.split('.').pop()
          const fileOrgName = this.styleTypeForm.image.name
          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode + '/styletypes'

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(this.styleTypeForm.image, metadata) // Upload the file

          uploadTask.on('state_changed', function (snapshot) {
            self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            const thisTask = uploadTask.snapshot.ref

            self.imagepath = process.env.VUE_APP_IMAGE_CDN_URL + '/' + thisTask.fullPath
            self.uploadStatus = 2

            const imageData = {}
            imageData.createdOn = new Date()
            imageData.file = newFilename
            imageData.orgFile = fileOrgName
            imageData.path = imageTargetPath
            imageData.meta = metadata
            imageData.uderId = self.$store.state.auth.userProfile.uid

            self.styleTypeForm.image = imageData

            self.imageFileStatus = true
          })
        } else {
          console.log('StyleType-imagedata not found')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .vary-image-container {
    display: flex;
    background-color: #fcfcfc;
    background-size: cover;
    border: 1px solid #DFE4EB;
    border-radius: 4px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 390px;
    overflow: hidden;

    &.noimg {
      padding-top: 66.67%;
    }

    .text {
      margin-top: -66.67%;
      color: #B5BCC6;
      text-align: center;

      i {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }
  }

  .vary-image-progress {
    transition: opacity .5s;

    margin-top: 16px;
    margin-bottom: -8px;
  }
</style>
