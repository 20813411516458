<template>
  <b-modal id="add-prospect-modal" scrollable size="lg">
    <template v-slot:modal-header>
      <b-container>
        <b-row>
          <b-col>
            <h3>{{ trans('prospects.create') }}</h3>
            <p class="description">
              {{ trans('prospects.create_description') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:default>
      <b-container>
        <b-row>
          <b-col>
            <b-form class="margin-bottom-16" @submit.prevent>
              <b-form-group id="prospect_unitid_group" :label="trans('prospects.form_add_new.unitid')" label-for="prospect_unitid">
                <b-form-select
                  id="prospect_unitid"
                  v-model="$v.prospectForm.unitId.$model"
                  name="prospect_unitid"
                  size="lg"
                  class="form-control-lg"
                  :state="formErrors.unitId"
                  aria-describedby="unitid-msg"
                  @change="getRoom($event)"
                >
                  <option :value="null">
                    {{ trans('prospects.form_add_new.unitid_placeholder') }}
                  </option>
                  <option v-for="( unit, index ) in projectUnits" :key="index + '-' + unit.name" :value="index">
                    {{ unit.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback id="unitid-msg">
                  <span v-if="!$v.prospectForm.unitId.required">{{ trans('prospects.form_add' +
                    '_new.errors.unitid') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="prospect_unit_rooms" :label="trans('prospects.form_add_new.choose_rooms')" label-for="prospect_unit_rooms" :style="(!aRoomIsSelected) ? 'opacity:0.3' : ''">
                <b-form-checkbox
                  v-for="room in rooms"
                  :key="room.id"
                  v-model="$v.prospectForm.rooms.$model"
                  :value="room.value"
                  :state="formErrors.rooms"
                  :disabled="room.disabled"
                  aria-describedby="rooms-msg"
                  inline
                >
                  {{ room.text }}
                </b-form-checkbox>
                <b-form-invalid-feedback :style="(!rooms.length && aRoomIsSelected) ? 'display: block !important' : ''">
                  <span>{{ trans('prospects.form_add_new.errors.no_rooms') }}</span>
                </b-form-invalid-feedback>
                <b-form-invalid-feedback id="rooms-msg" :state="formErrors.rooms">
                  <span v-if="!$v.prospectForm.rooms.required">{{ trans('prospects.form_add_new.errors.rooms') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
        {{ trans('global.cancel') }}
      </b-button>
      <b-button
        ref="submitbtn"
        variant="primary"
        size="md"
        :style="{ width: submitbtnwidth ? submitbtnwidth : 'auto' }"
        class="padding-left-60 padding-right-60"
        @click="createProspect"
      >
        <span v-if="!loading">{{ trans('prospects.save') }}</span>
        <b-spinner v-else variant="white"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  const fb = require('../../../../firebaseConfig')
  const varyConfig = require('../../../../varyConfig')

  export default {
    name: 'ModalAddProspect',
    mixins: [validationMixin],
    data () {
      return {
        loading: false,
        prospectForm: {
          title: '',
          rooms: [],
          unitId: null,
          projectId: null
        },
        projectTypes: varyConfig.projectTypes,
        roomCategories: varyConfig.roomCategories,
        submitbtnwidth: null,
        unitRooms: [ // Default mockup rooms
          { cat: 3, value: 1, disabled: true },
          { cat: 4, value: 2, disabled: true },
          { cat: 4, value: 3, disabled: true },
          { cat: 1, value: 4, disabled: true },
          { cat: 5, value: 5, disabled: true },
          { cat: 6, value: 6, disabled: true }
        ]
      }
    },
    validations () {
      return {
        prospectForm: {
          unitId: {
            required
          },
          rooms: {
            required
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        units: state => state.units.units
      }),
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      projectType () {
        return this.project !== undefined ? this.projectTypes[this.project.type] : 'loading'
      },
      formErrors () {
        return {
          unitId: (this.$v.prospectForm.unitId.$dirty && this.submitted) ? !this.$v.prospectForm.unitId.$error : null,
          rooms: (this.$v.prospectForm.rooms.$dirty && this.submitted) ? !this.$v.prospectForm.rooms.$error : null
        }
      },
      projectUnits () {
        return this.units.filter((unit) => {
          return unit.projectId === this.project._id
        })
      },
      aRoomIsSelected () {
        return this.prospectForm.unitId !== null
      },
      rooms () {
        const cat = []

        return this.unitRooms.map((room) => {
          let text = this.trans('global.' + this.roomCategories[room.cat])

          const count = cat.filter(c => c === room.cat).length

          if (count > 0) {
            text = text + ' ' + count
          }

          cat.push(room.cat)

          return { text, value: room.value, disabled: room.disabled ? room.disabled : false }
        })
      }
    },
    methods: {

      // Fetch the rooms belonging to a unit
      getRoom (id) {
        const roomsArray = []

        if (id !== null) {
          const roomObjects = this.projectUnits[id].rooms

          if (roomObjects !== undefined) {
            Object.keys(roomObjects).map((room) => {
              const currentRoom = roomObjects[room]
              roomsArray.push({ cat: currentRoom.category, value: currentRoom.id })
              return true
            })
          }
        } else {
          this.prospectForm.unitId = null
        }

        this.unitRooms = roomsArray
      },
      createProspect () {
        this.submitbtnwidth = this.$refs.submitbtn ? this.$refs.submitbtn.clientWidth : 'auto'
        this.loading = true
        this.submitted = true

        this.$v.prospectForm.$touch()

        if (this.$v.prospectForm.$anyError) {
          this.loading = false
          return
        }

        const unitData = this.projectUnits[this.prospectForm.unitId]

        const title = this.project.name + ' - ' + unitData.name
        const slug = this.createSlug(title, 'prospects/slugHelper', this.project._id)

        // If a room has been selected, mark it as selected
        Object.keys(unitData.rooms).map((room) => {
          const selected = this.prospectForm.rooms.find(r => r === parseInt(room))

          if (selected !== undefined) {
            unitData.rooms[room].selected = 1
          } else {
            unitData.rooms[room].selected = 0
          }
        })

        const varycode = this.doPaddy(this.$store.state.configs.varyCode)

        fb.varycodesCollection.doc(varycode).set({
          createdOn: new Date(),
          varycode,
          userId: this.userProfile.uid,
          type: 3 // 1 = project, 2 = unit, 3 = prospect
        }).then(() => {
          fb.prospectsCollection.add({
            createdOn: new Date(),
            title,
            slug,
            deleted: false,
            rooms: unitData.rooms,
            userId: this.userProfile.uid,
            unitId: unitData._id,
            projectId: this.project._id,
            varycode
          }).then((res) => {
            if (res.id !== undefined && res.id !== '') {
              fb.varycodesCollection.doc(varycode).set({
                typeId: res.id
              }, { merge: true })
            }
            this.prospectForm.title = ''
            this.prospectForm.unitId = null
            this.submitted = false
            this.loading = false
            this.hideModal('add-prospect-modal')
            this.$store.commit('configs/setProjectTopbarMenu', 'prospects')
          }).catch((err) => {
            console.log('Could not save prospect:')
            console.log(err)
          })
        })
      },
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      }
    }
  }
</script>
