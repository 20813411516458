<template>
  <b-modal id="add-room-modal" scrollable size="lg">
    <template v-slot:modal-header>
      <b-container>
        <b-row>
          <b-col>
            <h3>{{ trans('rooms.new_room') }}</h3>
            <p class="description">
              {{ trans('rooms.add_new') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:default>
      <b-container>
        <b-row>
          <b-col>
            <b-form class="margin-bottom-16" @submit.prevent>
              <b-form-group id="room_category_group" :label="trans('global.category')" label-for="room_category">
                <b-form-select
                  id="room_category"
                  v-model="$v.roomForm.category.$model"
                  name="room_category"
                  size="lg"
                  class="form-control-lg"
                  :state="formErrors.category"
                  aria-describedby="category-msg"
                >
                  <option :value="null">
                    {{ trans('rooms.room_category_desc') }}
                  </option>
                  <option value="1">
                    {{ trans('global.kitchen') }}
                  </option>
                  <option value="2">
                    {{ trans('global.kitchen_livingroom') }}
                  </option>
                  <option value="3">
                    {{ trans('global.livingroom') }}
                  </option>
                  <option value="4">
                    {{ trans('global.bedroom') }}
                  </option>
                  <option value="5">
                    {{ trans('global.bath') }}
                  </option>
                  <option value="6">
                    {{ trans('global.wc') }}
                  </option>
                  <option value="7">
                    {{ trans('global.laundryroom') }}
                  </option>
                  <option value="8">
                    {{ trans('global.wc_laundryroom') }}
                  </option>
                  <option value="9">
                    {{ trans('global.exterior') }}
                  </option>
                  <option value="10">
                    {{ trans('global.plot') }}
                  </option>
                  <option value="11">
                    {{ trans('global.hall') }}
                  </option>
                  <option value="12">
                    {{ trans('global.loft') }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback id="category-msg">
                  <span v-if="!$v.roomForm.category.required">{{ trans('rooms.form_add_new_errors.category') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="room_size_group" :label="trans('rooms.square_meters')" label-for="room_size">
                <b-form-input
                  id="room_size"
                  v-model="roomForm.size"
                  name="room_size"
                  class="form-control-lg"
                  category="text"
                  :placeholder="trans('rooms.square_meters_description')"
                  aria-describedby="name-msg"
                  trim
                  @keyup.13="createRoom"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <b-button
        variant="light"
        size="md"
        class="padding-left-60 padding-right-60"
        @click="cancel()"
      >
        {{ trans('global.cancel') }}
      </b-button>
      <b-button
        ref="submitbtn"
        variant="primary"
        size="md"
        :style="{ width: submitbtnwidth ? submitbtnwidth : 'auto' }"
        class="padding-left-60 padding-right-60"
        @click="createRoom"
      >
        <span v-if="!loading">{{ trans('rooms.save_room') }}</span>
        <b-spinner v-else variant="white"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  /*
    Project-and-unit-categories (hardcoded for now):
    1. Hytter
    2. Leiligheter
    3. Hus
    4. Næring
    5. Annet

    Room-categories (hardcoded for now):
    1. Kjøkken
    2. Kjøkken/Stue
    3. Stue
    4. Soverom
    5. Bad
    6. WC
    7. Vaskerom
    8. WC/Vaskerom
    9. Eksteriør
    10. Tomt
   */

  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  const fb = require('../../../../firebaseConfig')

  export default {
    name: 'ModalAddRoom',
    mixins: [validationMixin],
    data () {
      return {
        loading: false,
        roomForm: {
          category: null,
          size: ''
        },
        submitbtnwidth: null
      }
    },
    validations () {
      return {
        roomForm: {
          category: {
            required
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        units: state => state.units.units
      }),
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      formErrors () {
        return {
          category: (this.$v.roomForm.category.$dirty && this.submitted) ? !this.$v.roomForm.category.$error : null
        }
      },
      unit () {
        return this.units.find(unit => unit.slug === this.$route.params.unitslug)
      },
      rooms () {
        return this.unit.rooms !== undefined ? this.unit.rooms : false
      }
    },
    methods: {
      createRoom () {
        this.submitbtnwidth = this.$refs.submitbtn ? this.$refs.submitbtn.clientWidth : 'auto'
        this.loading = true
        this.submitted = true

        this.$v.roomForm.$touch()

        if (this.$v.roomForm.$anyError) {
          this.loading = false
          return
        }

        const id = this.rooms ? parseInt(Object.keys(this.rooms).pop()) + 1 : 0

        fb.unitsCollection.doc(this.unit._id).update({
          [`rooms.${id}`]: {
            id,
            createdOn: new Date(),
            category: this.roomForm.category,
            size: this.roomForm.size,
            userId: this.userProfile.uid
          }
        }).then(() => {
          this.roomForm.size = 0
          this.roomForm.category = null
          this.submitted = false
          this.loading = false
          this.hideModal('add-room-modal')
          this.$store.commit('configs/setProjectTopbarMenu', 'description')
        }).catch((err) => {
          console.log('Could not save room:')
          console.log(err)
        })
      },
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      }
    }
  }
</script>
