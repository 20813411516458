<template>
  <div class="vheading" :class="cls">
    <component :is="type">
      {{ title }}
    </component>
    <div v-if="buttonOptions.modal" class="htools">
      <b-button v-if="buttonOptions.modal === 'add-unit-modal'" v-b-modal.add-unit-modal :variant="buttonOptions.variant" :size="buttonOptions.size" :class="buttonOptions.class">
        {{ buttonOptions.title }}
      </b-button>
      <ModalAddUnit v-if="buttonOptions.modal === 'add-unit-modal'"></ModalAddUnit>

      <b-button v-if="buttonOptions.modal === 'add-room-modal'" v-b-modal.add-room-modal :variant="buttonOptions.variant" :size="buttonOptions.size" :class="buttonOptions.class">
        {{ buttonOptions.title }}
      </b-button>
      <ModalAddRoom v-if="buttonOptions.modal === 'add-room-modal'"></ModalAddRoom>

      <b-button v-if="buttonOptions.modal === 'add-prospect-modal'" v-b-modal.add-prospect-modal :variant="buttonOptions.variant" :size="buttonOptions.size" :class="buttonOptions.class">
        {{ buttonOptions.title }}
      </b-button>
      <ModalAddProspect v-if="buttonOptions.modal === 'add-prospect-modal'"></ModalAddProspect>

      <b-button v-if="buttonOptions.modal === 'add-style-modal'" v-b-modal.add-style-modal :variant="buttonOptions.variant" :size="buttonOptions.size" :class="buttonOptions.class">
        {{ buttonOptions.title }}
      </b-button>
      <ModalAddStyle v-if="buttonOptions.modal === 'add-style-modal'"></ModalAddStyle>
    </div>
    <div v-else-if="buttonOptions.title" class="htools">
      <b-button :href="href" :variant="buttonOptions.variant" :size="buttonOptions.size" :class="buttonOptions.class">
        {{ buttonOptions.title }}
      </b-button>
    </div>
  </div>
</template>

<script>
  import ModalAddUnit from '../Views/Projects/Modals/AddUnit'
  import ModalAddRoom from '../Views/Projects/Modals/AddRoom'
  import ModalAddProspect from '../Views/Projects/Modals/AddProspect'
  import ModalAddStyle from '../Views/Projects/Modals/AddStyle'

  export default {
    name: 'Heading',
    components: {
      ModalAddUnit,
      ModalAddRoom,
      ModalAddStyle,
      ModalAddProspect
    },
    props: {
      type: {
        type: String,
        default: 'h1',
        required: true
      },
      title: {
        type: String,
        default: 'Missing title [prop title]',
        required: true
      },
      cls: {
        type: String,
        default: 'margin-top-60 margin-bottom-30'
      },
      button: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        buttonDefaultOptions: {
          variant: 'primary',
          size: 'md',
          class: 'padding-left-60 padding-right-60',
          href: ''
        }
      }
    },
    computed: {
      buttonOptions () {
        return {
          ...this.buttonDefaultOptions,
          ...this.button
        }
      },
      href () {
        return this.buttonOptions.href !== undefined ? this.buttonOptions.href : false
      }
    }
  }
</script>
